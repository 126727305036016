export const IS_BROWSER = typeof window !== 'undefined'

export const GLOBAL_STORE_KEY = '___PRISMIC___'

export const IMAGE_FIELD_KEYS = [
  'dimensions',
  'alt',
  'copyright',
  'url',
  'localFile',
]
