// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-hochzeit-jsx": () => import("./../src/pages/hochzeit.jsx" /* webpackChunkName: "component---src-pages-hochzeit-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-partner-jsx": () => import("./../src/pages/partner.jsx" /* webpackChunkName: "component---src-pages-partner-jsx" */),
  "component---src-pages-raeumlichkeiten-index-jsx": () => import("./../src/pages/raeumlichkeiten/index.jsx" /* webpackChunkName: "component---src-pages-raeumlichkeiten-index-jsx" */),
  "component---src-pages-restaurant-jsx": () => import("./../src/pages/restaurant.jsx" /* webpackChunkName: "component---src-pages-restaurant-jsx" */),
  "component---src-pages-tagungen-jsx": () => import("./../src/pages/tagungen.jsx" /* webpackChunkName: "component---src-pages-tagungen-jsx" */),
  "component---src-templates-news-jsx": () => import("./../src/templates/news.jsx" /* webpackChunkName: "component---src-templates-news-jsx" */),
  "component---src-templates-room-jsx": () => import("./../src/templates/room.jsx" /* webpackChunkName: "component---src-templates-room-jsx" */)
}

